import React, { useState, useRef } from "react"
import { MarkdownViewer } from "../Markdown"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const FAQDropdown = ({ label, question, answer }) => {
  const answerRef = useRef()

  const [answerHeight, setAnswerHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const toggleAnswer = () => {
    if (isOpen) {
      setAnswerHeight(0)
    } else {
      setAnswerHeight(answerRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const styles = {
    maxHeight: answerHeight
  }

  return (
    <div className="faq__outer">
      <div className={`faq__inner ${isOpen ? "faq__open" : ""}`}>
        {label && <p className="faq__label">{label}</p>}
        <div className="faq__question" onClick={toggleAnswer}>
          <p>{question}</p>
          <FontAwesomeIcon icon={faChevronDown} color="#545454" />
        </div>

        <div className="faq__answer" style={styles} ref={answerRef}>
          <MarkdownViewer markdown={answer} />
        </div>
      </div>
    </div>
  )
}

export default FAQDropdown
