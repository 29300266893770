import React from "react"
import ImageMeta from "../ImageMeta"
import "./ImageStrip.scss"

const ImageStrip = ({ images = [] }) => {
  if (images.length <= 0) return <></>

  const styles = {
    display: "grid",
    gridTemplateColumns: `repeat(${images.length}, 1fr)`
  }
  return (
    <div className="image-strip" style={styles}>
      {images.map((img, i) => (
        <ImageMeta key={i} publicId={img} cloudname="nuvolum" width="auto" />
      ))}
    </div>
  )
}

export default ImageStrip
