import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"
import { Section, Container } from "../components/Containers"
import { MarkdownViewer } from "../components/Markdown"
import { ImpactSlider } from "../components/Sliders"
import { FAQDropdown } from "../components/Accordion"
import ImageStrip from "../components/ImageStrip/ImageStrip"

const MallardPointePage = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { hero, heading, blurb, cards, slider, faq, imageStrip } = post

  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schemaPage}
        pathname={location.pathname}
      />

      <div className="mallard-pointe-page">
        <ImageMeta
          publicId={hero.image}
          cloudName="nuvolum"
          width="auto"
          className="is-hidden-mobile"
        />
        <ImageMeta
          publicId={hero.imageMobile}
          cloudName="nuvolum"
          width="auto"
          className="is-hidden-tablet"
        />

        <Section>
          <Container>
            <div className="has-text-centered">
              <h1>{heading}</h1>
              <h2>{blurb}</h2>
            </div>

            <div className="grid-even-2 mt-4">
              {cards.map((card, i) => (
                <div key={i} className="mallard-project-card">
                  <h3>{card.heading}</h3>
                  <MarkdownViewer markdown={card.blurb} />
                </div>
              ))}
            </div>
          </Container>
        </Section>

        {/* <div className="voices-banner">
          <Container className="mb-0 mt-0">
            <a href="/voices/#public-comments" title="See community voices">
              <h3 className="has-text-centered">
                See why BRIG finds the developer's "Final Application" to be
                incomplete.
              </h3>
            </a>
          </Container>
        </div> */}

        <Section colorBack>
          <Container>
            <h4>LATE BREAKING NEWS!</h4>
            <p>
              On August 29, 2023, the developer’s Bruce Dorfman wrote a letter
              to City Manager Robert Zadnik, in which he stated in part:
            </p>
            <p>
              <i>
                “… provided [City] staff concurs that a CEQA exemption is
                appropriate for Mallard Pointe, … we will submit alternate plans
                to the City …. These schemes include removing all or a portion
                of the apartment building and replacing it with four- plexes and
                three-plexes …. However, if the CEQA determination indicates
                that the project will effectively need an Environmental Impact
                Report … neither our original application nor any of the
                alternatives are economically viable and we will need to
                consider more intensive redevelopment options for the property
                allowed under state law.”
              </i>
            </p>
            <p>
              Following receipt of Mr. Dorfman’s threatening letter, City staff
              recommended that the Mallard Pointe project be exempted from CEQA
              review.
            </p>
            <p>
              On October 31, a mere two weeks before the special Planning
              Commission meeting (November 14) to consider the Mallard Pointe
              project, the staff first made the public aware of the developer’s
              alternative project – which substitutes a combination of six
              structures (four-plexes and three-plexes) for the apartment
              building. Some of these structures significantly exceed the height
              of the originally proposed apartment building.
            </p>
            <p>
              Inexplicably, City staff has requested the Planning Commission to
              consider the alternative project, as well as the original project,
              at the upcoming Planning Commission meetings, even though the
              alternative project has not been thoroughly vetted and the public,
              to the extent it is even aware of the alternative, has had no
              reasonable opportunity to review or consider it.
            </p>
              <br/>
                <h4>NEWS FLASH:</h4>
            <p>
              At its November 14 special meeting — lasting over seven hours and attended in person and on Zoom by over 250 members of the public — the Planning Commission ruled 7-0 to reject planning staff’s recommendation to exempt the Mallard Pointe project from environmental review under CEQA. The Commission also provided comments and direction to the developer on its proposed alternate project.
            </p>
          </Container>
        </Section>

        <ImageStrip images={imageStrip} />

        <Section colorBack zeroBottom>
          <Container className="mb-4">
            <MarkdownViewer markdown={slider.text} />
          </Container>
          <ImpactSlider slides={slider.slider} />
        </Section>

        <Section>
          <Container>
            <h2 id="faq">{faq.heading}</h2>
            <div className="faq__block">
              {faq.accordion.map((item, i) => (
                <FAQDropdown
                  key={i}
                  label={item.label}
                  question={item.question}
                  answer={item.answer}
                />
              ))}
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

MallardPointePage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const mallardPointePageQuery = graphql`
  query mallardPointeQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          image
          imageMobile
        }
        imageStrip
        heading
        blurb
        cards {
          heading
          blurb
        }
        slider {
          text
          slider {
            button {
              destination
              appearance
              buttonText
              href
            }
            image
            imageMobile
            heading
          }
        }
        faq {
          heading
          accordion {
            label
            question
            answer
          }
        }
        schemaPage
      }
    }
  }
`

export default MallardPointePage
